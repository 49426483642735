import React, { useState } from "react";
import { Container, Nav, Navbar, NavDropdown } from "react-bootstrap";
import { HiPlusCircle } from "react-icons/hi";
import { Link } from "react-router-dom";

const Header = () => {
  const handleShow = () => setShow(true);
  const [show, setShow] = useState(false);
  return (
    <>
      <header className="header">
        <Navbar bg="light" expand="lg" className="py-3" fixed="top">
          <Container>
            <Navbar.Brand href="/">Real Estate</Navbar.Brand>
            <Navbar.Toggle aria-controls="basic-navbar-nav" />
            <Navbar.Collapse id="basic-navbar-nav">
              <Nav className="me-auto">
                <NavDropdown
                  className="header-dropdown"
                  title="Nagpur"
                  id="basic-nav-dropdown"
                >
                  <Link to="admin-dashboard" className="dropdown-item">
                    Admin Dashboard
                  </Link>
                  <Link to="user-dashboard" className="dropdown-item">
                    User Dashboard
                  </Link>
                  <Link to="agent-dashboard" className="dropdown-item">
                    Agent Dashboard
                  </Link>
                  <Link to="builder-dashboard" className="dropdown-item">
                    Builder Dashboard
                  </Link>
                  <Link to="all-pages-demo" className="dropdown-item">
                    All Pages Demo
                  </Link>
                </NavDropdown>
              </Nav>
              <Nav className="ms-auto">
                <Link
                  to="/user-dashboard"
                  className="nav-link abtn-primary-custom me-3"
                >
                  <HiPlusCircle size={18} /> post property
                </Link>
                <Link
                  to="/post-your-property"
                  className="nav-link abtn-primary-custom1 me-3"
                >
                  <HiPlusCircle size={18} /> post your requirment
                </Link>
                <Link to="/login" className="nav-link loginbtn">
                  login
                </Link>
                <Link to="/signupform" className="nav-link signbtn">
                  sign up
                </Link>
              </Nav>
            </Navbar.Collapse>
          </Container>
        </Navbar>
      </header>
    </>
  );
};

export default Header;
