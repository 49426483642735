import React from "react";

const Demo = () => {
  return (
    <div>
      <div className="boxx1">
        <div className="titlesss">
          <h1>pranay</h1>
        </div>
      </div>
    </div>
  );
};

export default Demo;
