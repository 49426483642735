import React from "react";
import { Container, Row, Col } from "react-bootstrap";
import UserDetailsCard from "../../component/UserDetailsCard";
import AgentDashBoardLayout from "../../layout/DashBoardLayouts/AgentDashBoardLayout";

const AgentProfile = () => {
  const Activity = [
    {
      No_of_Activity: "123",
      Title: "lists of property",
      url: "builder-activity-table",
    },
    {
      No_of_Activity: "123",
      Title: "lists of event",
      url: "builder-activity-table",
    },
    {
      No_of_Activity: "123",
      Title: "leads",
      url: "builder-activity-table",
    },
    {
      No_of_Activity: "123",
      Title: "Total recomendation",
      url: "builder-activity-table",
    },
    {
      No_of_Activity: "123",
      Title: "matching properties with requirements",
      url: "builder-activity-table",
    },
    {
      No_of_Activity: "123",
      Title: "posted requirements",
      url: "builder-activity-table",
    },
  ];
  return (
    <>
      <AgentDashBoardLayout>
        <Container>
          <Row>
            <Col>
              <UserDetailsCard
                Name="pranay masulkar"
                UserId="89358484"
                ReraNo="68456515JHGHJUB"
                MobileNo="8624043380"
                EmalId="pravinfreelance@gmail.com"
                Address="Gopal Nagar, Nagpur, Pin Code : 440009 "
                Experiance="4 Years"
              />
            </Col>
          </Row>
          {/* <Row className="d-flex justify-content-between align-items-strech">
            <Col xs={12}>
              <h2 className="text-capitalize text-shark font-rubic fw-500 mb-3">
                activities
              </h2>
            </Col>
            {Activity.map((Item) => (
              <Col className="d-flex align-items-stretch">
                <Card
                  bg="primary"
                  text="light"
                  border="primary"
                  className="w-100"
                >
                  <Card.Img variant="top" src="holder.js/100px180" />
                  <Card.Body>
                    <Card.Title className="h2 fw-600">
                      {Item.No_of_Activity}
                    </Card.Title>
                    <Card.Text className="text-capitalize fw-500">
                      {Item.Title}
                    </Card.Text>
                    <Link to="/builder-activity-table" className="text-white">
                      {" "}
                      view More
                    </Link>
                  </Card.Body>
                </Card>
              </Col>
            ))}
          </Row> */}
        </Container>
      </AgentDashBoardLayout>
    </>
  );
};

export default AgentProfile;
